const HEADER_LINKS: { [key: string]: string } = {
  MARKETPLACE: 'https://openloot.com/games/boss-fighters',
  BLOG: 'https://medium.com/@PlayBossFighters',
  TEAM: 'https://pixward-games.com/',
  SIGN_UP: 'https://space.bossfighters.game/#/register',
  WIKI: 'https://wiki.bossfighters.game/',
  QUEST: 'https://quest.bossfighters.game/'
}

export default HEADER_LINKS;
