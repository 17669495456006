import React, {useEffect, useState, useContext} from 'react';
import {useNavigate} from 'react-router-dom';

import useScreenSizeHook, {SCREEN_TYPES} from '../../hooks/useScreenSizeHook/useScreenSizeHook';
import CONTEXT from '../../context/';

import GradientButton from '../GradientButton/GradientButton';

import {getHotLink} from '../../api';
import {setItem} from '../../localStorage';
import {LOCAL_STORAGE_NAMES} from '../../localStorage/types';

import HEADER_LINKS from './constants';
import ASSETS from '../../assets/images/constants';

import {
  BurgerLine,
  BurgerMenu,
  BurgerWrapper,
  BurgerWrapperUl, ChangeLanguageButton,
  DisableWrapper,
  ImportantBtnWrapper,
  Logo,
  Wrapper,
  WrapperHref,
  WrapperLi,
  WrapperLogo,
  WrapperNav,
  WrapperUl,
} from './styles';
import './index.css';

const Header = () => {
  const {screenType} = useScreenSizeHook();
  const {language, switchLanguage, getText} = useContext(CONTEXT.GLOBAL.LANGUAGE);
  const navigate = useNavigate();

  const [isBurgerMenuVisible, setIsBurgerMenuVisible] = useState<boolean>(false);
  const [isOutAnimation, setIsOutAnimation] = useState<boolean>(true);

  const [hotLink, setHotLink] = useState<string>('');

  const scrollToElement = (elemId: string) => {
    const targetElement = document.getElementById(elemId);

    if (screenType !== SCREEN_TYPES.DESKTOP) {
      menuClickHandler()
    }

    if (targetElement) {
      targetElement.scrollIntoView({behavior: 'smooth'})
    } else {
      setItem(LOCAL_STORAGE_NAMES.SCROLL_TO, elemId);
      navigate('/')
    }
  }

  const menuClickHandler = () => {
    if (isBurgerMenuVisible) {
      setIsOutAnimation(true)
      setTimeout(() => setIsBurgerMenuVisible(false), 300)
    } else {
      setIsOutAnimation(false)
      setIsBurgerMenuVisible(true)
    }
  }

  const getHotLinkHandler = async () => {
    try {
      const response = await getHotLink();
      if (response.data) {
        setHotLink(response.data.link);
      }
    } catch (e: any) {
      console.log(e);
    }
  }

  useEffect(() => {
    getHotLinkHandler().catch(console.error)
  }, []);

  useEffect(() => {
    if (isBurgerMenuVisible) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [isBurgerMenuVisible])

  return (
    <Wrapper screenType={screenType} className='header-visible'
      url={screenType === SCREEN_TYPES.DESKTOP ? ASSETS.components.header.desktop.HeaderBackground :
        ASSETS.components.header.mobile.HeaderBackground}>
      <WrapperLogo className='scaleOnHover' screenType={screenType}>
        <Logo src={ASSETS.components.header.desktop.BossFightersLogo}
          title={getText('general.BossFighters')} aria-label={getText('general.BossFighters')}
          alt={getText('general.BossFighters')}
          onClick={() => {
            if (window.location.href.includes('privacy-policy')) {
              navigate('/')
            } else {
              window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            }
          }}
        />
      </WrapperLogo>

      <WrapperNav screenType={screenType}>
        <WrapperUl screenType={screenType}>
          {screenType === SCREEN_TYPES.DESKTOP && <>
            <WrapperLi className='scaleOnHover text-xs font-w-700 white-color text-uppercase'
              title={getText('header.About')} aria-label={getText('header.About')}
              onClick={() => scrollToElement('boss-fighters-about-block')}
            >
              {getText('header.About')}
            </WrapperLi>
            <WrapperLi className='text-xs font-w-700 scaleOnHover white-color'>
              <WrapperHref href={HEADER_LINKS.WIKI} target='_blank'
                title={getText('header.Wiki')} aria-label={getText('header.Wiki')}
              >
                {getText('header.Wiki')}
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='scaleOnHover'>
              <WrapperHref href={HEADER_LINKS.QUEST} target='_blank'
                title={getText('header.Quest')} aria-label={getText('header.Quest')}>
                <GradientButton text={getText('header.Quest')}
                  afterBgGradient='ellipse at center, #FF0000, #FF8C00'
                  width={7}
                  height={2}
                  className={'font-w-700 white-color text-s'}
                  style={{
                    background: 'radial-gradient(ellipse at center, #FF0000, #FF8C00)',
                    transform: 'skew(-12deg)',
                  }}/>
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='scaleOnHover text-xs font-w-700 white-color'>
              <WrapperHref href={HEADER_LINKS.MARKETPLACE} target='_blank'
                title={getText('header.Marketplace')}
                aria-label={getText('header.Marketplace')}>
                {getText('header.Marketplace')}
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='text-xs font-w-700 pointer-events-none'>
              <WrapperHref title={getText('header.Streaming')} aria-label={getText('header.Streaming')}
                disabled={true} aria-disabled={true}
              >
                {getText('header.Streaming')}
              </WrapperHref>
              <DisableWrapper
                className='text-coming-soon font-w-700 dark-blue-color text-uppercase'>
                {getText('general.ComingSoon')}
              </DisableWrapper>
            </WrapperLi>
            <WrapperLi className='scaleOnHover text-xs font-w-700 white-color'>
              <WrapperHref href={HEADER_LINKS.BLOG} target='_blank'
                title={getText('header.Blog')} aria-label={getText('header.Blog')}
              >
                {getText('header.Blog')}
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='scaleOnHover text-xs font-w-700 white-color'>
              <WrapperHref href={HEADER_LINKS.TEAM} target='_blank'
                title={getText('header.Team')} aria-label={getText('header.Team')}
              >
                {getText('header.Team')}
              </WrapperHref>
            </WrapperLi>
          </>}
          <ImportantBtnWrapper screenType={screenType}>
            <WrapperLi className='scaleOnHover'>
              <WrapperHref href={hotLink} target='_blank'
                title={getText('header.Hot')} aria-label={getText('header.Hot')}>
                <GradientButton text={getText('header.Hot')}
                  afterBgGradient='ellipse at center, #FF0000, #FF8C00'
                  width={screenType === SCREEN_TYPES.DESKTOP ? 5.5 : 16}
                  height={screenType === SCREEN_TYPES.DESKTOP ? 2 : 6.2}
                  className={`font-w-700 white-color ${screenType === SCREEN_TYPES.DESKTOP ? 'text-s' : 'text-m-mob'}`}
                  style={{
                    background: 'radial-gradient(ellipse at center, #FF0000, #FF8C00)',
                    transform: 'skew(-12deg)',
                  }}/>
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='scaleOnHover'>
              <WrapperHref href={HEADER_LINKS.SIGN_UP} target='_blank'
                title={getText('header.SignUp')} aria-label={getText('header.SignUp')}
              >
                <GradientButton text={getText('header.SignUp')}
                  width={screenType === SCREEN_TYPES.DESKTOP ? 6.7 : 19}
                  height={screenType === SCREEN_TYPES.DESKTOP ? 2 : 6.2}
                  className={`font-w-700 dark-blue-color ${screenType === SCREEN_TYPES.DESKTOP ? 'text-s' : 'text-m-mob'}`}
                  style={{
                    background: 'linear-gradient(to right, #00FFFF, #FCFF21)',
                    transform: 'skew(-12deg)'
                  }}/>
              </WrapperHref>
            </WrapperLi>
            <WrapperLi style={{width: `${screenType === SCREEN_TYPES.DESKTOP ? 2.3 : 7.3}vw`}}>
              <ChangeLanguageButton onClick={() => switchLanguage(language === 'en' ? 'zh' : 'en')}
                className={`scaleOnHover font-w-700 white-color ${screenType === SCREEN_TYPES.DESKTOP ? 'text-s' : 'text-m-mob'}`}
              >
                {language === 'en' ? '中文' : 'EN'}
              </ChangeLanguageButton>
            </WrapperLi>
          </ImportantBtnWrapper>
        </WrapperUl>
      </WrapperNav>

      {screenType !== SCREEN_TYPES.DESKTOP && (
        <BurgerWrapper isCross={!isOutAnimation} onClick={menuClickHandler}
          title={`${isOutAnimation ? 'Open' : 'Close'} menu`}
          aria-label={`${isOutAnimation ? 'Open' : 'Close'} menu`}>
          <BurgerLine/>
          <BurgerLine isVisible={isOutAnimation}/>
          <BurgerLine/>
        </BurgerWrapper>
      )}

      {isBurgerMenuVisible && (
        <BurgerMenu isOutAnimation={isOutAnimation}>
          <BurgerWrapperUl>
            <WrapperLi className='scaleOnHover text-m-mob font-w-700 white-color text-uppercase'
              marginBottom={2} title={getText('header.About')} aria-label={getText('header.About')}
              onClick={() => scrollToElement('boss-fighters-about-block')}>
              {getText('header.About')}
            </WrapperLi>
            <WrapperLi className='scaleOnHover text-m-mob font-w-700 white-color' marginBottom={2}>
              <WrapperHref href={HEADER_LINKS.WIKI} target='_blank'
                title={getText('header.Wiki')}
                aria-label={getText('header.Wiki')}
              >
                {getText('header.Wiki')}
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='scaleOnHover' marginBottom={2}>
              <WrapperHref href={HEADER_LINKS.QUEST} target='_blank'
                title={getText('header.Quest')} aria-label={getText('header.Quest')}>
                <GradientButton text={getText('header.Quest')}
                  afterBgGradient='ellipse at center, #FF0000, #FF8C00'
                  width={18}
                  height={6.2}
                  className='font-w-700 white-color text-m-mob'
                  style={{
                    background: 'radial-gradient(ellipse at center, #FF0000, #FF8C00)',
                    transform: 'skew(-12deg)',
                  }}/>
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='scaleOnHover text-m-mob font-w-700 white-color' marginBottom={2}>
              <WrapperHref href={HEADER_LINKS.MARKETPLACE} target='_blank' onClick={menuClickHandler}
                title={getText('header.Marketplace')}
                aria-label={getText('header.Marketplace')}>
                {getText('header.Marketplace')}
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='text-m-mob font-w-700' marginBottom={8}>
              <WrapperHref title={getText('header.Streaming')} aria-label={getText('header.Streaming')}
                disabled={true} aria-disabled={true}
              >
                {getText('header.Streaming')}
              </WrapperHref>
              <DisableWrapper className='text-m-mob font-w-700 dark-blue-color text-uppercase'>
                {getText('general.ComingSoon')}
              </DisableWrapper>
            </WrapperLi>
            <WrapperLi className='scaleOnHover text-m-mob font-w-700 white-color' marginBottom={2}>
              <WrapperHref href={HEADER_LINKS.BLOG} target='_blank' onClick={menuClickHandler}
                title={getText('header.Blog')} aria-label={getText('header.Blog')}>
                {getText('header.Blog')}
              </WrapperHref>
            </WrapperLi>
            <WrapperLi className='scaleOnHover text-m-mob font-w-700 white-color' marginBottom={2}>
              <WrapperHref href={HEADER_LINKS.TEAM} target='_blank' onClick={menuClickHandler}
                title={getText('header.Team')} aria-label={getText('header.Team')}>
                {getText('header.Team')}
              </WrapperHref>
            </WrapperLi>
          </BurgerWrapperUl>
        </BurgerMenu>
      )}
    </Wrapper>
  );
};

export default Header;